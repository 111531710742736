import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { DOCS_MENU } from "../../../lib/docs-menu-flat";
import { SiteContext } from "../../global/site-context";
import { pageDocPath } from "../../../../helpers";
import { cn } from "../../../lib/helpers";
import { SelectMenu } from "../filters";
import { TinyDownArrow } from "../../icon/tiny-down-arrow";

export const DocumentationMenu = ({ docs, menu }) => {
  const [active, setActive] = useState();
  const site = useContext(SiteContext);

  let docList = [];
  DOCS_MENU.forEach((h, i) => {
    const docsInCategory = docs.filter((d) => d.menu === h.id);
    if (docsInCategory.length === 0) {
      console.warn(`No docs found for category ${h.title}`);
    }
    docList.push({
      label: h.parent ? `- ${h.title}` : h.title,
      url:
        h.path || docsInCategory[0]?.menuParent
          ? pageDocPath(docsInCategory[0])
          : "none",
    });
    docsInCategory.shift();
    docsInCategory.forEach((d) => {
      docList.push({
        label: !d.menuParent
          ? `-- ${d.menuTitle || d.title}`
          : d.menuTitle || d.title,
        url: pageDocPath(d),
      });
    });
  });

  return (
    <>
      <div className="block xl:hidden w-full">
        <SelectMenu label="Documentation" menu={docList} />
      </div>
      <ul
        className="relative z-50 hidden xl:block text-navText"
        style={{ fontSize: 13 }}
      >
        {DOCS_MENU.map((h, i) =>
          !h.parent ? (
            <React.Fragment key={i}>
              <li className="mt-4 mb-1 font-semibold">
                {docs.filter((d) => d.menu === h.id)?.[0]?.menuParent ? (
                  <Link
                    to={pageDocPath(docs.filter((d) => d.menu === h.id)?.[0])}
                    className={cn(
                      "hover:text-link block leading-tight",
                      h.id === site.doc.menu && site.doc.menuParent
                        ? "text-black"
                        : null
                    )}
                  >
                    {h.menuTitle || h.title}
                  </Link>
                ) : (
                  <>{h.menuTitle || h.title}</>
                )}
              </li>
              {DOCS_MENU.filter((c) => c.parent === h.id).map((c, j) => (
                <SubMenu
                  isActive={
                    active === `${i}--${j}` || (!active && menu === c.id)
                  }
                  setActive={setActive}
                  docs={docs}
                  subCategory={c}
                  hash={`${i}--${j}`}
                  key={`${i}--${j}`}
                  menuParent={
                    docs.filter((d) => d.menu === c.id)?.[0]?.menuParent
                  }
                />
              ))}
              <ArticlesByCategory categoryId={h.id} docs={docs} />
            </React.Fragment>
          ) : null
        )}
      </ul>
    </>
  );
};

const SubMenu = ({
  subCategory,
  docs,
  isActive,
  setActive,
  hash,
  menuParent,
}) => {
  const site = useContext(SiteContext);
  // @todo check slug
  const activeParent = subCategory.id === site.doc.menu && site.doc.menuParent;

  return (
    <>
      <li
        className={cn(
          "ml-1 py-1 group hover:text-link cursor-pointer border-l border-dark-10 hover:border-link",
          isActive && activeParent ? "font-medium text-black" : null
        )}
      >
        {menuParent ? (
          <Link
            to={pageDocPath(docs.filter((d) => d.menu === subCategory.id)?.[0])}
            onClick={() => {
              setActive(hash);
            }}
            className={"ml-3 block hover:text-link group"}
          >
            <TinyDownArrow
              rotate={isActive ? null : -90}
              className={
                "mt-1 ml-1 float-right text-auxiliary group-hover:text-link"
              }
            />
            {subCategory.title}
          </Link>
        ) : (
          <div
            onClick={() => {
              setActive(hash);
            }}
          >
            <TinyDownArrow
              rotate={isActive ? null : -90}
              className={
                "mt-1 ml-1 float-right text-auxiliary group-hover:text-link"
              }
            />
            <span className="ml-3">{subCategory.title}</span>
          </div>
        )}
      </li>
      {isActive && (
        <ArticlesByCategory
          categoryId={subCategory.id}
          docs={docs}
          subMenu={true}
        />
      )}
    </>
  );
};

const ArticlesByCategory = ({ categoryId, docs, subMenu }) => {
  const site = useContext(SiteContext);
  const list = docs
    .filter((d) => d.menu === categoryId)
    .filter((d) => !d.menuParent);

  return list.map((d, i) => {
    const isActive = site.doc.id === d.id;
    return !d.parent ? (
      <li
        className={cn(
          "ml-1 block border-l border-dark-10 hover:border-link",
          i === list.length - 1 && subMenu ? "mb-1" : null
        )}
        style={{
          paddingLeft: subMenu ? "1.5rem" : "0.75rem",
        }}
        key={i}
      >
        <Link
          to={pageDocPath(d)}
          className={cn(
            "hover:text-link block leading-tight py-1",
            isActive ? "font-medium text-black" : null
          )}
        >
          {d.menuTitle || d.title}
        </Link>
      </li>
    ) : (
      <li>SUB-SUB-MENU</li>
    );
  });
};
