import React from "react";
import { cn } from "../../lib/helpers";

export const Divider = ({ className, padding }) => (
  <hr
    className={cn(
      "h-0.5 bg-dark-10",
      padding === "none" ? null : "my-8",
      className
    )}
  />
);

const LinesBgMap = new Map([
  ["graphic-1", "-40px -370px"],
  ["graphic-2", "-40px -750px"],
  ["graphic-3", "-40px -2850px"],
]);

export const LinesBg = ({ section }) => (
  <div
    className="p-24 opacity-0 lg:opacity-100"
    style={{
      background: "url(/static/img/lines-bg.svg)",
      backgroundPosition: LinesBgMap.get(section.anchor),
      backgroundSize: "1440px 4059px",
    }}
  ></div>
);
