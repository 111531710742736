import React from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { DOCS_MENU } from "../../lib/docs-menu-flat";

const query = graphql`
  query DocsListQuery {
    docs: allSanityDocumentation(
      sort: { fields: [orderRank] }
      filter: { status: { eq: "published" }, isFeatured: { eq: true } }
    ) {
      edges {
        node {
          _id
          _type
          title
          menuTitle
          slug {
            current
          }
          _rawExcerpt
          menu
          menuParent
        }
      }
    }
  }
`;

// @todo Move article only items to the bottom, under folders

export const DocsList = ({ section, hideFilters }) => {
  const data = useStaticQuery(query) || {};
  const docs = data && data.docs && mapEdgesToNodes(data.docs);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
      {DOCS_MENU.filter((d) => !d.parent && !d.hideCard).map((m, i) => {
        const children = DOCS_MENU.filter((c) => c.parent === m.id);
        return (
          <div
            className="bg-white border border-dark-5 rounded-xl p-4 shadow-sm tracking-tight"
            key={i}
          >
            <h2 className="text-lg font-medium mb-4">
              <a href={m.path} className="text-link hover:text-black">
                {m.title}
              </a>
            </h2>
            <ul className="leading-tight ml-1 mb-2">
              {docs
                .filter(
                  (d) =>
                    d.menu === m.id || children?.find((c) => d.menu === c.id)
                )
                .slice(0, 5)
                .map((d) => (
                  <li key={d._id} className="mt-4">
                    <a
                      href={pageDocPath(d)}
                      className="group hover:text-link flex align-start inline-block"
                    >
                      <FontAwesomeIcon
                        icon={faBookmark}
                        className="mr-2 mt-0.5 text-dark-20 group-hover:text-link"
                        style={{ width: 12, height: 16 }}
                      />
                      {d.menuTitle === "Overview" || !d.menuTitle
                        ? d.title
                        : d.menuTitle}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
